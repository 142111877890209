<template>
  <div class="executed-list-wrapper">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="executed-item-box" v-for="(item, index) in list" :key="'executed-item' + index" :title="item">
        <div class="top">{{ item[0].taskDate }}</div>
        <div class="bottom">
          <ul>
            <li @click="getPreCheckStatus(item1.id)" v-for="item1 of item" :key="item1.id">
              <div class="left">
                <i class="iconfont icondianjianguanli"></i>
                <span>{{ item1.taskName }}</span>
              </div>
              <div class="right">
                <span>{{ item1.taskStatus }}</span>
                <i class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </van-list>
    <Dialog :show="show" :info="dialogInfo" @handleCloseDialogClick="handleCloseDialogClick" @handleQueryDialogClick="handleQueryDialogClick" />
    <Dialog1 :show="show1" :info="dialogInfo1" @handleQueryDialogClick="handleQueryDialogClick1" />
  </div>
</template>

<script>
import Vue from 'vue'
import Dialog1 from './Dialog'
import Dialog from '@/components/Dialog'
import { List } from 'vant'
import { getMyTodoCheckTaskList, getPreCheckStatus } from '@/api/check'

Vue.use(List)
export default {
  name: 'ExecutedList',
  components: {
    Dialog1,
    Dialog,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      show1: false,
      show: false,
      dialogInfo: {
        question: '执行过程中其他人无法进入该点检任务，是否开始点检？',
        cancerTxt: '取消',
        queryTxt: '确定',
      },
      dialogInfo1: {
        question: '当前张三正在执行点检任务，您无法进入',
      },
      params: {
        page: 0,
        size: 10,
      },
      currentTaskId: 0,
    }
  },
  methods: {
    /**
     * 列表
     */
    getMyTodoCheckTaskList() {
      if (this.params.page === 0) {
        this.list = []
      }
      getMyTodoCheckTaskList(this.params).then((res) => {
        const content = res.content

        Object.keys(content).forEach((key) => {
          let arr = []
          content[key].forEach((item) => {
            switch (item.taskStatus) {
              case -2:
                item.taskStatus = '已过期'
                break
              case -1:
                item.taskStatus = '已撤销'
                break
              case 0:
                item.taskStatus = '待执行'
                break
              case 1:
                item.taskStatus = '点检中'
                break
              case 2:
                item.taskStatus = '待审批'
                break
              case 3:
                item.taskStatus = '审批不通过'
                break
              case 4:
                item.taskStatus = '已完结'
                break
            }
            arr.push(item)
          })
          this.list.push(arr)
        })
          this.loading = false
        if (this.list.length >= res.totalElements|| Object.keys(content).length == '0') {
          this.finished = true
            this.loading = true
        }
        this.params.page = this.params.page + 1
      })
    },
    /**
     * 进入点检任务线路前确认接口
     */
    getPreCheckStatus(id) {
      this.currentTaskId = id
      getPreCheckStatus(id).then((res) => {
        if (res.retCode === 0) {
          //异常
          this.show1 = true
          this.dialogInfo1.question = res.retMsg
        }
        if (res.retCode === 1) {
          //正常
          this.show = true
          this.dialogInfo.question = res.retMsg
        }
      })
    },
    handleCloseDialogClick() {
      this.show = false
    },
    handleQueryDialogClick() {
      this.$router.push({
        path: '/taskList',
        query: {
          taskId: this.currentTaskId,
        },
      })
      this.show = false
    },
    /**
     * 弹窗确认（当前张三正在执行点检任务，您无法进入）
     */
    handleQueryDialogClick1() {
      this.show1 = false
    },
    onLoad() {
      // 异步更新数据
      setTimeout(() => {
        this.getMyTodoCheckTaskList()
        // this.loading = true
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.executed-list-wrapper {
  box-sizing: border-box;
  padding: 1rem;
  background-color: #f1f1f1;
  .executed-item-box {
    margin-bottom: 1rem;
    background-color: white;
    border-radius: 0.3rem;
    .top {
      @include wh(100%, 2.2rem);
      border-bottom: 1px solid #f1f1f1;
      box-sizing: border-box;
      padding-left: 0.6rem;
      line-height: 2.2rem;
      font-size: 0.85rem;
      color: #999999;
    }
    .bottom {
      ul {
        li {
          @include wh(100%, 2.5rem);
          border-bottom: 1px solid #f1f1f1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding: 0 0.6rem;
          .left {
            width: 0;
            flex: 1;
            display: flex;
            align-items: center;
            padding-right: 0.3rem;
            i {
              color: #4a8cff;
              font-size: 1rem;
              margin-right: 0.5rem;
              margin-top: -0.2rem;
            }
            span {
              font-size: 0.7rem;
              color: #333333;
            }
          }
          .right {
            display: flex;
            align-items: center;
            i {
              font-size: 0.9rem;
              margin-left: 0.1rem;
              margin-top: -0.3rem;
            }
            span {
              font-size: 0.7rem;
              color: #4a8cff;
            }
          }
        }
      }
    }
  }
}
</style>
