<template>
  <div class="executed-list-wrapper">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="executed-item-box" v-for="item in list" :key="item.id" :title="item" @click="handleDetailClick(item)">
        <div class="top">
          <div class="left">{{ item.checkTime }}</div>
          <div class="right">
            <span class="status3" v-if="item.taskStatus === -2">已过期</span>
            <span class="status3" v-else-if="item.taskStatus === -1">已撤销</span>
            <span class="status3" v-else-if="item.taskStatus === 0">待执行</span>
            <span class="status3" v-else-if="item.taskStatus === 1">点检中</span>
            <span class="status1" v-else-if="item.taskStatus === 2">待审批</span>
            <span class="status3" v-else-if="item.taskStatus === 3">审批不通过</span>
            <span class="status2" v-else-if="item.taskStatus === 4">已通过</span>
            <span class="status3" v-else>不通过</span>
            <i class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"></i>
          </div>
        </div>
        <div class="bottom">
          <div class="left">
            <div class="icon">
              <img :src="iconImg" alt="icon" />
            </div>
          </div>
          <div class="right">
            <h3>{{ item.taskName }}</h3>
            <p>{{ item.approvalDesc }}</p>
          </div>
        </div>
      </div>
    </van-list>
    <!--步骤条-->
    <div class="step-box" v-if="show" @click="show = false">
      <van-steps direction="vertical" :active="stepData.length != '1' ? 1 : 0">
        <van-step v-for="(item, index) of stepData" :key="item.title">
          <h3>{{ item.title }}</h3>
          <span v-if="item.time">{{ item.time }}</span>
          <p v-if="index < 1">{{ item.content }}</p>
          <p v-if="item.status === 0 && index === 1">{{ item.content }}</p>
          <p style="color: #4acb03" v-if="item.status === 1 && index === 1">
            {{ item.content }}
          </p>
          <p style="color: #ff6666" v-if="item.status === 2 && index === 1">
            {{ item.content }}
          </p>
          <p v-if="item.actualapprovalUserName" style="margin-top: -1rem">
            审批人员：{{
              item.actualapprovalUserName ? item.actualapprovalUserName : ''
            }}
          </p>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { List, Step, Steps } from 'vant'
import 'vant/lib/list/style'
import 'vant/lib/step/style'
import 'vant/lib/steps/style'
import iconImg from '@/assets/img/check/my-check-icon.png'
import { getMyCheckedTaskList } from '@/api/check'

Vue.use(Step).use(Steps)
Vue.use(List)
export default {
  name: 'MyCheckList',
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      iconImg: iconImg,
      show: false,
      stepData: [],
      stepData1: [
        {
          title: '提交任务单',
          time: '2019-09-11 12:00:00',
          content: '您提交了点检任务单',
          status: 0,
        },
        {
          title: '待审批',
          time: '',
          content: '等待审批中...',
          status: 0,
        },
      ],
      stepData2: [
        {
          title: '提交任务单',
          time: '2019-09-11 12:00:00',
          content: '您提交了点检任务单',
          status: 1,
        },
        {
          title: '已完结',
          time: '2019-09-11 12:00:00',
          content: '李四审批通过',
          status: 1,
        },
      ],
      stepData3: [
        {
          title: '提交任务单',
          time: '2019-09-11 12:00:00',
          content: '您提交了点检任务单',
          status: 2,
        },
        {
          title: '审批不通过',
          time: '2019-09-11 12:00:00',
          content: '李四审批不通过, 抽检不合格',
          status: 2,
        },
      ],
      params: {
        page: 0,
        size: 10,
      },
    }
  },
  methods: {
    /**
     * 列表
     */
    getMyCheckedTaskList() {
      if (this.params.page === 0) {
        this.list = []
      }
      getMyCheckedTaskList(this.params).then((res) => {
        res.content.forEach((item) => {
          this.list.push(item)
        })
          this.loading = false
        if (this.list.length >= res.totalElements|| res.content.length == '0') {
          this.finished = true
          this.loading = true

        }
        this.params.page = this.params.page + 1
      })
    },
    /**
     * 详情
     */
    handleDetailClick(item) {
      const {
        taskStatus,
        checkTime,
        approvalTime,
        approvalDesc,
        actualapprovalUserName,
      } = item
      if (taskStatus === -2) {
        // 已过期
        this.stepData = [
          {
            title: '提交任务单',
            time: checkTime,
            content: '您提交了点检任务单',
            status: 0,
          },
          {
            title: '已过期',
            time: '',
            content: '等待审批中...',
            actualapprovalUserName: actualapprovalUserName,
            status: 0,
          },
        ]
      } else if (taskStatus === -1) {
        // 已撤销
        this.stepData = [
          {
            title: '提交任务单',
            time: checkTime,
            content: '您提交了点检任务单',
            status: 0,
          },
          {
            title: '已撤销',
            time: '',
            content: '等待审批中...',
            actualapprovalUserName: actualapprovalUserName,
            status: 0,
          },
        ]
      } else if (taskStatus === 0) {
        // 待执行
        this.stepData = [
          {
            title: '提交任务单',
            time: checkTime,
            content: '您提交了点检任务单',
            status: 0,
          },
          {
            title: '待执行',
            time: '',
            content: '等待审批中...',
            actualapprovalUserName: actualapprovalUserName,
            status: 0,
          },
        ]
      } else if (taskStatus === 1) {
        // 点检中
        this.stepData = [
          {
            title: '提交任务单',
            time: checkTime,
            content: '您提交了点检任务单',
            status: 0,
          },
          {
            title: '点检中',
            time: '',
            content: '等待审批中...',
            actualapprovalUserName: actualapprovalUserName,
            status: 0,
          },
        ]
      } else if (taskStatus === 2) {
        // 待审批
        this.stepData = [
          {
            title: '提交任务单',
            time: checkTime,
            content: '您提交了点检任务单',
            status: 0,
          },
          {
            title: '待审批',
            time: '',
            content: '等待审批中...',
            actualapprovalUserName: actualapprovalUserName,
            status: 0,
          },
        ]
      } else if (taskStatus === 3) {
        // 审批不通过
        this.stepData = [
          {
            title: '提交任务单',
            time: checkTime,
            content: '您提交了点检任务单',
            status: 2,
          },
          {
            title: '审批不通过',
            time: approvalTime,
            content: approvalDesc,
            actualapprovalUserName: actualapprovalUserName,
            status: 2,
          },
        ]
      } else {
        // 4.已完结
        this.stepData = [
          {
            title: '提交任务单',
            time: checkTime,
            content: '您提交了点检任务单',
            status: 1,
          },
          {
            title: '已完结',
            time: approvalTime,
            content: approvalDesc,
            actualapprovalUserName: actualapprovalUserName,
            status: 1,
          },
        ]
      }
      this.show = true
    },
    onLoad() {
      // 异步更新数据
      setTimeout(() => {
        this.getMyCheckedTaskList()
        // this.loading = true
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.executed-list-wrapper {
  box-sizing: border-box;
  padding: 1rem;
  background-color: #f1f1f1;
  .executed-item-box {
    margin-bottom: 1rem;
    background-color: white;
    border-radius: 0.3rem;
    .top {
      @include wh(100%, 2.2rem);
      border-bottom: 1px solid #f1f1f1;
      box-sizing: border-box;
      padding: 0 0.6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-size: 0.85rem;
        color: #999999;
      }
      .right {
        display: flex;
        align-items: center;
        span {
          font-size: 0.7rem;
          color: #4a8cff;
          &.status1 {
            color: #4a8cff;
          }
          &.status2 {
            color: #4acb04;
          }
          &.status3 {
            color: #f56c6c;
          }
        }
        i {
          font-size: 0.9rem;
          margin-left: 0.1rem;
          margin-top: -0.3rem;
        }
      }
    }
    .bottom {
      @include wh(100%, 4rem);
      display: flex;
      align-items: center;
      .left {
        @include wh(4rem, 100%);
        box-sizing: border-box;
        padding: 0.75rem;
        .icon {
          @include wh(2.5rem, 2.5rem);
          img {
            @include wh(100%, 100%);
          }
        }
      }
      .right {
        margin-left: 0.5rem;
        h3 {
          font-size: 0.7rem;
          color: #333333;
          margin-bottom: 0.5rem;
        }
        p {
          font-size: 0.7rem;
          color: #666666;
        }
      }
    }
  }
  .step-box {
    position: fixed;
    @include wh(100%, 100%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    @include CC;
    padding: 20px;
    /deep/ .van-steps--vertical {
      padding: 1.2rem 0.5rem 0.5rem 3rem;
    }
    /deep/.van-steps {
      width: 15rem;
      border-radius: 0.2rem;
      .van-step__circle {
        width: 10px;
        height: 10px;
      }

      .van-hairline {
        &::after {
          border: 0 !important;
        }
      }
      .van-step__title {
        h3 {
          font-size: 0.75rem;
          color: #333333;
          font-weight: 600;
          margin-bottom: 0.3rem;
        }
        span {
          font-size: 0.6rem;
          color: #999999;
          margin-bottom: 0.8rem;
          display: block;
        }
        p {
          font-size: 0.7rem;
          color: #999999;
          margin-bottom: 1rem;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
